<!--申请应标资格 -->
<template>
  <!--  -->
  <div>
    <back />
    <div class='globle_border content'>
      <header>缴纳保证金</header>
      <!-- 表格 -->
      <div class="globle_table tableBox">
        <el-table max-height="540" :data="tableData">
          <el-table-column label="序号" width="60">
            <template slot-scope="scope">
              {{ scope.$index + 1 }} <!-- 这里可以获取到当前行的索引号 -->
            </template>
          </el-table-column>
          <el-table-column prop="productName" label="商品名称" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="specs" label="规格" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="enterBox" label="入箱数" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="brand" label="品牌" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="factoryName" label="厂家" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="expirationDay" label="商品保质期(天)" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="biddingPrice" label="中标价格(元)" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="depositAmount" label="履约保证金(元)" show-overflow-tooltip>
          </el-table-column>
        </el-table>
      </div>
      <footer>
        <div class="total-div">合计：<span class="total">{{ Number(depositAmount).toFixed(2) }}</span> 元</div>
        <div class="btn">
          <el-button type="primary" @click="handlePay">缴费</el-button>
        </div>
      </footer>
    </div>
    <child :showFlag="showFlag" @closeChildDialog="closeChildDialog"></child>
  </div>
</template>

<script>
import child from './../detail/paymentDet.vue'
export default {
  components: {
    child,
  },
  data () {
    return {
      loading: false,
      showFlag: false,//缴费组件显隐
      tableData: [],//数据
      tendersId: '',//请求id
      depositAmount: 0,//总价格
      id: '',//组件id
      sourceType: '',//平台资格
    }
  },
  created () {
    this.tendersId = this.$route.query.tendersId
    this.depositAmount = this.$route.query.depositAmount
    this.id = this.$route.query.id
    this.sourceType = this.$route.query.sourceType
    this.getRequest()
  },
  methods: {
    // 列表请求
    getRequest () {
      this.loading = true;
      this.$axios.get(this.$api.sendMyWinTendersProduct, {
        params: {
          tendersId: this.tendersId
        }
      }).then((res) => {
        const { code, result } = res.data
        if (code == 100) {
          this.tableData = result
          this.loading = false;
        }
      }).catch((err) => {
        console.log(err);
        this.loading = false;
      })

    },
    // 检查用户当前支付平台绑定状态
    handlePay () {
      //  缓存第三方支付的获取验证码参数和支付参数
      let query = {
        sourceId: this.id, // 对应平台的主键id
        fee: this.depositAmount, // 缴费金额
        supplierId: this.$store.state.userInfo.supplierId, //供应商id
        sourceType: 3, // 支付来源：1平台应标资格；2独立标应标资格；3保证金
      }
      this.$store.commit('setPayQuery', query)
      this.$axios.post(this.$api.select_token, {
        phoneNum: this.$store.state.userInfo.userAccount, // 手机号
        supplierId: this.$store.state.userInfo.supplierId, // 供应商id
      }).then((res) => {
        if (res.data.code == 100) {
          // result为空时该供应商没有绑定第三方支付平台
          if (res.data.result == null) {
            this.showFlag = true
          } else {
            this.$router.replace({ name: 'paymentPage' })
          }
        }
      });
    },
    //  接受子组件调用的关闭弹出框方法
    closeChildDialog () {
      this.showFlag = false
    },

  },
}
</script>

<style scoped lang='scss'>
.content {

  // 头部
  header {
    text-align: center;
    font-family: Microsoft YaHei, Microsoft YaHei;
    font-weight: bold;
    font-size: 20px;
    color: #1A1A1A;
    margin-bottom: 20px;
  }
}

footer {
  .total-div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 10px;

    .total {
      font-family: Microsoft YaHei, Microsoft YaHei;
      font-weight: 400;
      font-size: 24px;
      color: #EB2500;
      margin-right: 5px;
    }
  }

  .btn {
    text-align: center;
  }


}
</style>